<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" :class="className" fill="#3183FF" />
    <path
      d="M22.1667 16.75V13.8333C22.1667 13.375 21.7917 13 21.3333 13H11.3333C10.875 13 10.5 13.375 10.5 13.8333V22.1667C10.5 22.625 10.875 23 11.3333 23H21.3333C21.7917 23 22.1667 22.625 22.1667 22.1667V19.25L24.075 21.1583C24.6 21.6833 25.5 21.3083 25.5 20.5667V15.425C25.5 14.6833 24.6 14.3083 24.075 14.8333L22.1667 16.75Z"
      fill="white"
    />
  </svg>
</template>
<script setup lang="ts">
import { computed } from "vue";
type Props = {
  expired: boolean;
};
const props = defineProps<Props>();
const className = computed(() => {
  return props.expired ? "Icon__expired" : "Icon__active";
});
</script>
<style scoped lang="scss">
.Icon {
  &__active {
    fill: $color-primary;
  }
  &__expired {
    fill: $color-neutral-7;
  }
}
</style>
