<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" :class="className" fill="#AA50E1" />
    <path
      d="M25.4999 10.5H10.4999C9.58325 10.5 8.83325 11.25 8.83325 12.1667V22.1667C8.83325 23.0833 9.58325 23.8333 10.4999 23.8333H14.6666V24.6667C14.6666 25.125 15.0416 25.5 15.4999 25.5H20.4999C20.9583 25.5 21.3333 25.125 21.3333 24.6667V23.8333H25.4999C26.4166 23.8333 27.1583 23.0833 27.1583 22.1667L27.1666 12.1667C27.1666 11.2417 26.4166 10.5 25.4999 10.5ZM24.6666 22.1667H11.3333C10.8749 22.1667 10.4999 21.7917 10.4999 21.3333V13C10.4999 12.5417 10.8749 12.1667 11.3333 12.1667H24.6666C25.1249 12.1667 25.4999 12.5417 25.4999 13V21.3333C25.4999 21.7917 25.1249 22.1667 24.6666 22.1667Z"
      fill="white"
    />
  </svg>
</template>
<script setup lang="ts">
import { computed } from "vue";
type Props = {
  expired: boolean;
};
const props = defineProps<Props>();
const className = computed(() => {
  return props.expired ? "Icon__expired" : "Icon__active";
});
</script>
<style scoped lang="scss">
.Icon {
  &__active {
    fill: $color-secondary;
  }
  &__expired {
    fill: $color-neutral-7;
  }
}
</style>
